import React from 'react'

const Payment = () => {
  return (
    <div>
    
<section class="body-font min-h-screen bg-gray-100  text-gray-600">
  <div class="container mx-auto  flex max-w-3xl flex-wrap justify-center rounded-lg bg-white px-5 py-24">
  
    <div class="flex-wrap md:flex">
      <div class="mx-auto">
        <img class="mx-auto mt-12 h-52 w-52 rounded-lg border p-2 md:mt-0" src="https://i.imgur.com/FQS7fFC.png" alt="step" />
        <div>
          <h1 class="font-laonoto mt-4 text-center text-xl font-bold">Product</h1>
          <p class="mt-2 text-center font-semibold text-gray-600">Vendor or Marketplace</p>
          <p class="mt-1 text-center font-medium text-red-500">040-12-00-01166166-001</p>
        </div>
       
        <div class="mx-auto w-52">
          <div class="m-4">
            <div class="flex w-full items-center justify-center">
              <label class="flex h-16 w-full cursor-pointer flex-col border-4 border-dashed border-gray-200 hover:border-gray-300 hover:bg-gray-100">
                <div class="mt-4 flex items-center justify-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                  </svg>

                  <p class="font-laonoto text-xs text-center  text-gray-400 group-hover:text-gray-600">Uplaod Payment Screenshot</p>
                </div>
                <input type="file" class="opacity-0" />
              </label>
            </div>
          </div>
        </div>
        <button class="mx-auto block rounded-md border bg-[#98d900] px-6 py-2 text-white outline-none">Upload</button>
      </div>
   
      <div class="mt-8 max-w-sm md:mt-0 md:ml-10 md:w-2/3">
        <div class="relative flex pb-12">
          <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
            <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
          </div>
          <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#98d900] text-white">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">STEP 1</h2>
            <p class="font-laonoto leading-relaxed">
             Scan <br />
              <strong>QR CODE </strong> 
            </p>
          </div>
        </div>
        <div class="relative flex pb-12">
          <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
            <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
          </div>
          <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#98d900] text-white">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">STEP 2</h2>
            <p class="font-laonoto leading-relaxed">Make  <b>Payment</b>.</p>
          </div>
        </div>
        <div class="relative flex pb-12">
          <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#98d900] text-white">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
              <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">STEP 3</h2>
            <p class="font-laonoto leading-relaxed">
              Upload<span> <b>Payments Screen Shot</b></span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default Payment