
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";


const firebaseConfig = {
  apiKey: "AIzaSyArZKs2l2yr5MdiXeCCB4IVTG21Yx91K7A",
  authDomain: "medherbsmarketing.firebaseapp.com",
  projectId: "medherbsmarketing",
  storageBucket: "medherbsmarketing.appspot.com",
  messagingSenderId: "787515898997",
  appId: "1:787515898997:web:189b6bcf7ffd43c6384c3c",
  measurementId: "G-8LHWSEE4WG"
};


// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export { firebase }

