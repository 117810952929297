import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firebase } from '../Firebase/config';
import 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
const Askuserinfo = () => {
  const navigate = useNavigate();

  const [referralId, setReferralId] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const referralId = searchParams.get('referralId');

    setReferralId(referralId);
  }, [location.search]);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({
    phoneNumber: '',
    address: '',
    panCardUrl: null,
    aadharCardUrl: null,
  });
  
  const [user, setUser] = useState(null);
  const [username, setUserName] = useState(null);
  const [useremail, setUserEmail] = useState(null);
  const [userphoto, setUserPhoto] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const authObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setUserName(user.displayName);
        setUserEmail(user.email);
        setUserPhoto(user.photoURL);
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        userRef.get().then((doc) => {
          if (doc.exists) {
            setUserData(doc.data());
          } else {
            console.log('No such document!');
          }
        });
      } else {
        setUser(null);
      }
    });

    return () => authObserver();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePanCardUpload = (e) => {
    const panimage = e.target.files[0];
    setUserData({ ...userData, panCardUrl: panimage });
  };

  const handleAadharCardUpload = (e) => {
    const aadharimage = e.target.files[0];
    setUserData({ ...userData, aadharCardUrl: aadharimage });
  };

  const storageRef = firebase.storage().ref();

  const handleSubmitStep1 = async () => {
    try {
      setLoading(true);

      const panimageRef = storageRef.child(userData.panCardUrl.name);
      const panUploadTask = panimageRef.put(userData.panCardUrl);

      const aadharimageRef = storageRef.child(userData.aadharCardUrl.name);
      const aadharUploadTask = aadharimageRef.put(userData.aadharCardUrl);

      const [panSnapshot, aadharSnapshot] = await Promise.all([
        panUploadTask,
        aadharUploadTask,
      ]);

      const panUrl = await panSnapshot.ref.getDownloadURL();
      const aadharUrl = await aadharSnapshot.ref.getDownloadURL();

      if (
        username &&
        useremail &&
        userphoto &&
        userData.phoneNumber &&
        userData.address &&
        panUrl &&
        aadharUrl
      ) {
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        const updatedUser = {
          ...userData,
          name: username,
          email: useremail,
          photo: userphoto,
          userId: user.uid,
          panCardUrl: panUrl,
          aadharCardUrl: aadharUrl,
          status:'Pending',
          referralId: referralId, // Assign referralId from userData
        };
        

        await userRef.set(updatedUser);
        setSuccess(true);
        setLoading(false);
        navigate('/');
        toast.success('Data submitted successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUserData({
          phoneNumber: '',
          address: '',
          panCardUrl: null,
          aadharCardUrl: null,
        });
      } else {
        let missingFields = [];
            if (!username) missingFields.push("Name");
            if (!useremail) missingFields.push("Email");
            if (!userData.phoneNumber) missingFields.push("Phone Number");
            if (!userData.address) missingFields.push("Address");
            if (!userData.panCardUrl) missingFields.push("Pan Card");
            if (!userData.aadharCardUrl) missingFields.push("Aadhar Card");
      
            toast.error(`Please fill in all the following fields: ${missingFields.join(', ')}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          console.error("Error submitting data:", error);
        } finally {
          setLoading(false); // Set loading state to false in case of error
        }
      };
   
  return (
    <div className="w-full bg-white p-10">
      {loading &&  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-800 z-50">
      <div class="flex justify-center items-center h-screen">
    <div class="rounded-full h-20 w-20 bg-green-800 animate-ping"></div>
  </div>
    </div>}
      {success && (
        <div className="bg-green-200 text-green-800 p-3 rounded mt-3">
          Data submitted successfully!
        </div>
      )}
    <div className="md:flex items-center border-b pb-6 border-gray-200">
             <div className="flex items-center md:mt-0 mt-4">
                 <div className="w-8 h-8 bg-gray-100 rounded flex items-center justify-center">
                     <p className="text-base font-medium leading-none text-black">01</p>
                 </div>
                 <p className="text-base ml-3 font-medium leading-4 text-gray-800">Signup With Google</p>
             </div>
             <div className="flex items-center md:mt-0 mt-4 md:ml-12">
                 <div className="w-8 h-8 bg-indigo-700 rounded flex items-center justify-center">
                     <p className="text-base font-medium leading-none text-white">02</p>
                 </div>
                 <p className="text-base ml-3 font-medium leading-4 text-gray-800">Personal Detail</p>
             </div>
            
            
         </div>
   <h1 tabIndex={0} role="heading" aria-label="profile information" className="focus:outline-none text-3xl font-bold text-gray-800 mt-12">
       Profile info
   </h1>
   <p role="contentinfo" className=" focus:outline-nonetext-sm font-light leading-tight text-gray-600 mt-4">
       Fill in the data for profile. It will take a couple of minutes. <br />
      
   </p>
   <h2 role="heading" aria-label="enter Personal data" className="text-xl font-semibold leading-7 text-gray-800 mt-10">
       Personal data
   </h2>
   <p className="text-sm font-light leading-none text-gray-600 mt-0.5">Your Details </p>
   <div className="flex mt-4">
  <div className="flex-shrink-0 w-full  md:mr-12 h-44 sm:h-32 sm:w-32 sm:mb-0">
    <img
      src={userphoto}
      alt=""
      className="object-cover object-center w-32 h-32 rounded dark:bg-gray-500"
    />
  </div>
</div>

   <div className="mt-4 md:flex items-center">
    
       <div className="flex flex-col">
           <label className="mb-3 text-sm leading-none text-gray-800">Your name</label>
           <input className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200" 
           type="text"
           name="Name"
           value={username}
           onChange={handleChange}
           readOnly
           placeholder="Your Name" />
       </div>
     
      
   </div>
   <div className="mt-12 md:flex items-center">
       <div className="flex flex-col">
           <label className="mb-3 text-sm leading-none text-gray-800">Email Address</label>
           <input  className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200" 
           type="email"
           name="email"
           value={useremail}
           onChange={handleChange}
           readOnly
           placeholder="Your Email" />
       </div>
       <div className="flex flex-col md:ml-12 md:mt-0 mt-8">
           <label className="mb-3 text-sm leading-none text-gray-800">Phone number</label>
           <input  className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200" 
           type="number"
           name="phoneNumber"
           value={userData.phoneNumber}
           onChange={handleChange}
           placeholder="Enter Mobile Number" />
       </div>
   </div>
   <div className="mt-12 md:flex items-center">
   <div class=" mb-6">
                         <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                             for="file_input">Upload Pan Card</label>
                            <input
id="panCardInput"
class="block w-full text-sm text-gray-600 border border-gray-200 rounded cursor-pointer file:hover:bg-gray-200 file:border-solid file:border-0 file:cursor-pointer dark:file:border-gray-700 dark:file:text-gray-300 dark:file:hover:bg-gray-500 dark:file:bg-gray-600 file:mr-5 file:px-5 file:py-3 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 file:border-r file:border-gray-300 file:bg-gray-100 "
type="file"
onChange={handlePanCardUpload}
/>

                     </div>
                     <div class=" md:ml-4 mb-6">
                         <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                             for="file_input">Upload Aadhar Card</label>
                         <input
id="aadharCardInput"
class="block w-full text-sm text-gray-600 border border-gray-200 rounded cursor-pointer file:hover:bg-gray-200 file:border-solid file:border-0 file:cursor-pointer dark:file:border-gray-700 dark:file:text-gray-300 dark:file:hover:bg-gray-500 dark:file:bg-gray-600 file:mr-5 file:px-5 file:py-3 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 file:border-r file:border-gray-300 file:bg-gray-100 "
type="file"
onChange={handleAadharCardUpload}
/>

                     </div>
   </div>
   <div className="flex flex-col">
           <label className="mb-3 text-sm leading-none text-gray-800">Your Referal Code</label>
           <input
  className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
  type="text"
  name="referralId" // Update name to referralId
  value={referralId}
  onChange={handleChange} // Update onChange to handle referralId changes
  readOnly
  placeholder="Enter Referral Code"
/>

       </div>
   <div className="mt-12 md:flex items-center">
       <div className="flex flex-col">
           <label className="mb-3 text-sm leading-none text-gray-800">Address</label>
           <textarea 
            className="w-72 md:w-96 bg-gray-100 text-sm h-32 font-medium leading-none text-gray-800 
            p-3 border rounded border-gray-200"
            type="text"
           name="address"
           value={userData.address}
           onChange={handleChange}
           placeholder="Enter Your Address"
            ></textarea>
       </div>
       
   </div>
   
   <div className="mt-12">
       <div className="py-4 flex items-center">
           <div className="bg-white dark:bg-gray-800 border rounded-sm border-gray-400 dark:border-gray-700 w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
               <input type="checkbox" tabIndex={0} aria-label="I agree with the terms of service" defaultChecked className="checkbox opacity-0 absolute cursor-pointer w-full h-full" />
               <div className="check-icon hidden bg-blue-500 text-white rounded-sm">
                   <svg className="icon icon-tabler icon-tabler-check" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                       <path stroke="none" d="M0 0h24v24H0z" />
                       <path d="M5 12l5 5l10 -10" />
                   </svg>
               </div>
           </div>
           <p className="text-sm leading-none ml-2">
               I agree with the <span className="text-indigo-700">terms of service</span>
           </p>
       </div>
   </div>
   <button onClick={handleSubmitStep1} role="submit"  className="flex items-center justify-center py-4 px-7 focus:outline-none bg-white border rounded border-gray-400 mt-7 md:mt-14 hover:bg-gray-100  focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
       <span className="text-sm font-medium text-center text-gray-800 capitalize">Submit</span>
       <svg className="mt-1 ml-3" width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M8.01 3H0V5H8.01V8L12 4L8.01 0V3Z" fill="#242731" />
       </svg>
   </button>
   
   <ToastContainer />

</div>
  )
}

export default Askuserinfo