import React, { useState, useEffect } from "react";
import { firebase } from "../Firebase/config";

const News = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      const db = firebase.firestore();
      const newsRef = db.collection("News");

      newsRef.get()
        .then((querySnapshot) => {
          const fetchedNews = [];
          querySnapshot.forEach((doc) => {
            fetchedNews.push({ ...doc.data(), id: doc.id });
          });

          setNews(fetchedNews);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          setIsLoading(false);
        });
    });

    return () => unsubscribe();
  }, []);

  const toggleDescription = (itemId) => {
    setExpandedDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [itemId]: !prevDescriptions[itemId],
    }));
  };

  console.log("News", news);

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="border-b mb-5 flex justify-between text-sm">
        <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">
          <span className="font-semibold inline-block">Our News</span>
        </div>
        {/* <a href="#">See All</a> */}
      </div>
      {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-[#98d900] block mx-auto" viewBox="0 0 24 24">
            <path
              d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
              data-original="#000000"
            />
          </svg>
        </div>
      ) : (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {news.map((item) => (
          <div className="rounded overflow-hidden shadow-lg flex flex-col" key={item.id}>
            <a href="#">
              <div className="relative">
                <img className="w-full" src={item.frontImage} alt={item.title} />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </div>
            </a>
            <div className="px-6 py-4 mb-auto">
              <a href="#" className="font-medium text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2">{item.title}</a>
              <p className="text-gray-500 text-sm">
                {expandedDescriptions[item.id]
                  ? item.description
                  : `${item.description.split(" ").slice(0, 15).join(" ")}...`}
                <button
                  className="text-indigo-600"
                  onClick={() => toggleDescription(item.id)}
                >
                  {expandedDescriptions[item.id] ? "Read Less" : "Read More"}
                </button>
              </p>
            </div>
            <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
              <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
                <span className="ml-1">{new Date(item.date).toLocaleString()}</span>
              </span>
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default News;
