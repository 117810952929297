import React, { useState, useEffect } from "react";
import BestSeller8 from '../components/BestSeller';
import { firebase } from "../Firebase/config";

const db = firebase.firestore();

function HomeTwo(props) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      const db = firebase.firestore();
      const GallerysRef = db.collection("HomeCarousel");

      GallerysRef.get()
        .then((querySnapshot) => {
          const galleryData = [];
          querySnapshot.forEach((doc) => {
            galleryData.push({ ...doc.data(), id: doc.id });
          });

          setGallery(galleryData);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          setLoading(false);
        });
    });

    return () => unsubscribe();
  }, []);

  console.log("Gallery", gallery);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === gallery.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => clearInterval(intervalId);
  }, [gallery, currentImageIndex]);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const ProductRef = db.collection("Product");
        const snapshot = await ProductRef.get();
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          averageRating: 0,
        }));
        setProductData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);

  useEffect(() => {
    productData.forEach(async (item, index) => {
      const reviewsRef = db
        .collection("Product")
        .doc(item.id)
        .collection("reviews")
        .where("approved", "==", "Active");

      const snapshot = await reviewsRef.get();
      const reviewsData = snapshot.docs.map((doc) => doc.data());

      if (reviewsData.length > 0) {
        const totalRating = reviewsData.reduce(
          (acc, curr) => acc + curr.rating,
          0
        );
        const avgRating = totalRating / reviewsData.length;
        const updatedProductData = [...productData];
        updatedProductData[index].averageRating = avgRating;
        setProductData(updatedProductData);
      }
    });
  }, [productData]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-[#98d900] block mx-auto" viewBox="0 0 24 24">
          <path
            d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
            data-original="#000000"
          />
        </svg>
      </div>
    );
  }

  return (
    <div className='home-2'>
      <div className="container">
        <section className="bg-cover bg-center mt-2 lg:mt-4 flex justify-center items-center">
          <div className="px-0">
            <div>
              <div className="lg:pr-1 lg:py-4">
                {gallery.length > 0 && gallery[currentImageIndex]?.frontImage && (
                  <img
                    src={gallery[currentImageIndex].frontImage}
                    className="h-full w-full object-cover rounded-xl"
                    alt={`Slider Image ${currentImageIndex + 1}`}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-center -mt-8 lg:-mt-12">
              {gallery.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 lg:w-4 lg:h-4 mx-1 rounded-full cursor-pointer ${index === currentImageIndex ? 'bg-white' : 'bg-gray-300'}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
      <BestSeller8 productData={productData} />
    </div>
  );
}

export default HomeTwo;
