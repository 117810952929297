import React from 'react';
import 'react-tabs/style/react-tabs.css';


function CompletedDeals(props) {
    return (
        <div>
             
         


            <section className="register login">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Coming Soon</h3>
                   
                    </div>
                </div>
              
                </div>
            </div>
            </section>

        </div>
    );
}

export default CompletedDeals;