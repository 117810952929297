

import img1 from '../images/icon/show.png'
import img2 from '../images/icon/done.png'
import img3 from '../images/icon/users.png'
const dataAbout = [
    {
        id: 1,
        title: 'What is Medherbs',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus aliquam parturient erat id vel, condimentum a, hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis. ',
        show: 'show'
    },
    {
        id: 2,
        title: 'How to start with Medherbs',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus aliquam parturient erat id vel, condimentum a, hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis. ',
    },
    {
        id: 3,
        title: 'What Cryptocurrencies can I use to purchase',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus aliquam parturient erat id vel, condimentum a, hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis. ',
    },
    {
        id: 4,
        title: 'How to buy & sell in Medherbs',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus aliquam parturient erat id vel, condimentum a, hendrerit egestas. Auctor cras diam, dui pulvinar elit. Egestas feugiat gravida in imperdiet facilisi tortor ac ultrices venenatis. ',
    },

   
]

export default dataAbout;