
import React, { useState, useEffect } from "react";
import { firebase } from "../Firebase/config";
import "firebase/firestore";
import "firebase/storage";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { FaStar } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const handleRatingSelection = (selectedRating) => {
    setRating(selectedRating === rating ? 0 : selectedRating);
  };
  const [bigImage, setBigImage] = useState(""); // State for the big image
  const [productdata, setProductData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleBuyNowClick = () => {
    setShowModal(true); // Step 3: Function to open the modal
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const db = firebase.firestore();
    const productRef = db.collection("Product").doc(id);

    productRef.get().then((doc) => {
      if (doc.exists) {
        setProductData(doc.data());
      } else {
        console.log("Document not found!");
      }
      setIsLoading(false);
    });
  }, []);



 

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        fetchUserData(authUser.uid);
      } else {
        setUser(null);
        setUserData(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);
  const fetchUserData = async (uid) => {
    try {
      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get();
      if (userDoc.exists) {
        const fetchedUserData = userDoc.data();
        setUserData(fetchedUserData);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };
  console.log(userData)
  
  const [shippingInfo, setShippingInfo] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    transactioid:'',
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const db = firebase.firestore();
      const ordersRef = db.collection("orders");
  
      if (!userData) {
        toast.error("Please log in before checkout", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/login");
        return;
      }
  
      if (!shippingInfo.transactioid.trim()) {
        toast.error("Please enter a transaction ID", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        return;
      }
  
      const currentDate = new Date();
  
      // Add shipping information to Firestore and get the order reference
      const orderRef = await ordersRef.add({
        firstName: userData ? userData.firstName : '',
        middleName: userData ? userData.middleName : '',
        lastName: userData ? userData.lastName : '',
        email: userData ? userData.email : '',
        phoneNumber: userData ? userData.phoneNumber : '',
        productname: productdata.title,
        productimage: productdata.images[0],
        productprice: productdata.offerprice,
        address: shippingInfo.address,
        transactioid: shippingInfo.transactioid,
        city: shippingInfo.city,
        state: shippingInfo.state,
        zipCode: shippingInfo.zipCode,
        orderConfirmation: 'Pending',
        orderDate: currentDate,
      });
  
      toast.success('Order placed successfully!');
  
      setLoading(false);
      setShowModal(false);
  
      // Navigate to the Orderdetails page with the orderRef.id
      navigate(`/OrderDetails?orderId=${orderRef.id}`);
  
    } catch (error) {
      console.error("Error completing purchase:", error);
      setLoading(false);
      // Handle error if necessary
    }
  };
  

  return (
    <div>
      {isLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-[#98d900] block mx-auto" viewBox="0 0 24 24">
        <path
          d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
          data-original="#000000"
        />
      </svg>
    </div>
    
        ) : (
          <div>
      <div class="font-sans tracking-wide p-4 lg:max-w-6xl max-w-2xl max-lg:mx-auto">
            <div class="grid items-start grid-cols-1 lg:grid-cols-5 gap-8">

                <div class="lg:col-span-3 text-center">
                <div className="lg:h-[350px] p-4 relative before:absolute before:inset-0  before:opacity-20 before:rounded">
                <img
  src={bigImage || productdata.images[0]}
  alt="Product"
  className="lg:w-11/12 w-full h-full rounded object-contain object-top"
/>

            </div>

            <div className="flex flex-wrap gap-4 mx-auto mt-4">
              {productdata.images && productdata.images.map((image, index) => (
                <div
                  key={index}
                  className="cursor-pointer p-1 relative before:absolute before:inset-0 before:bg-black before:opacity-20 before:rounded"
                  onClick={() => setBigImage(image)}
                >
                  <img src={image} alt={`Product${index + 1}`} className="w-20 h-16 object-contain" />
                </div>
              ))}
            </div>
                </div>

                <div class="lg:col-span-2">
                    <div class="flex flex-wrap items-start gap-4">
                        <div>
                            <h2 class="text-2xl font-extrabold text-gray-800">{productdata.title}</h2>

                            {/* <div class="flex space-x-1 mt-4">
                                <svg class="w-5 fill-orange-500" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                </svg>
                                <svg class="w-5 fill-orange-500" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                </svg>
                                <svg class="w-5 fill-orange-500" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                </svg>
                                <svg class="w-5 fill-orange-500" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                </svg>
                                <svg class="w-5 fill-[#CED5D8]" viewBox="0 0 14 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                </svg>
                            </div> */}
                        </div>

                    
                    </div>

                    <hr class="my-6" />

                    <div>
                        <h3 class="text-xl font-bold text-gray-800">Price</h3>
                        <p class="text-gray-800 text-4xl font-bold mt-4">₹{productdata.offerprice}<span class="text-sm ml-1">Tax included</span></p>
                        <p class="text-gray-400 text-xl"><strike>₹{productdata.price}</strike> </p>
                    </div>



                    <hr class="my-6" />

                    <div class="flex flex-wrap gap-4">
                        <button onClick={handleBuyNowClick} class="min-w-[200px] px-4 py-3 bg-orange-500 hover:bg-orange-600 text-white text-sm font-semibold rounded">Buy now</button>
                    </div>
                    {/* <h3 class="text-xl font-bold text-gray-800 mt-4">Technical Specification</h3>
                    <ul class="space-y-3 list-disc mt-4 pl-4 text-sm text-gray-800 whitespace-pre-line">
  {productdata.technicalSpecification}
</ul> */}
                </div>
            </div>

            <div class="mt-6 max-w-2xl">
                <h3 class="text-xl font-bold text-gray-800 font-moto">Technical Specification</h3>

                <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto">   {productdata.technicalSpecification}</p>

                <div class="mt-6">
                    <h3 class="text-xl font-bold text-gray-800 font-moto">Product Description</h3>
                    <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto">  {productdata.description}</p>
                </div>
                <div class="mt-6">
                    <h3 class="text-xl font-bold text-gray-800 font-moto">About</h3>
                    <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto">  {productdata.about}</p>
                </div>
                <div class="mt-6">
                    <h3 class="text-xl font-bold text-gray-800 font-moto">Care Instruction</h3>
                    <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto"> Store in a cool, dry place
Keep out of reach of children
Close the lid tightly after use to maintain freshness
</p>
                </div>
                <div class="mt-6">
                    <h3 class="text-xl font-bold text-gray-800 font-moto">Warranty and Support</h3>
                    <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto"> 100% satisfaction guarantee
Contact our customer support for any queries or issues

</p>
                </div>
                <div class="mt-6">
                    <h3 class="text-xl font-bold text-gray-800 font-moto">Shipping and Return Policy</h3>
                    <p class="text-sm text-gray-600 mt-4 whitespace-pre-line font-moto">Free shipping on orders over ₹500
                    7-day return policy for damaged or defective products

</p>
                </div>
            </div>
        </div>
        {showModal && (
      <div className="fixed md:mt-0 mt-48 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-0 ">
      <div className="font-[sans-serif] bg-gray-50 max-h-full overflow-y-auto">
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 h-full">
    
        <div class="bg-[#3f3f3f] lg:h-screen lg:sticky lg:top-0">
          <div class="relative h-full">
            <div class="p-8 lg:overflow-auto lg:h-[calc(100vh-60px)]">
              <h2 class="text-2xl font-bold text-white">Order Summary</h2>
              <div class="space-y-6 mt-10">
                <div class="grid sm:grid-cols-2 items-start gap-6">
                  <div class="px-4 py-6 shrink-0 bg-gray-50 rounded-md">
                    <img  src={productdata.images[0]} class="w-full object-contain" />
                  </div>
                  <div>
                    <h3 class="text-base text-white">{productdata.title}</h3>
                    <ul class="text-xs text-white space-y-3 mt-4">
                      <li class="flex flex-wrap gap-4">Total Price <span class="ml-auto">₹{productdata.offerprice}</span></li>
                    </ul>
                  </div>
                </div>
                <div>
      <img src="qrcode.jpg" class="h-64 w-64 object-contain mb-8 mt-8" alt="QR Code" />
      <h4 class="flex flex-wrap  text-base text-white"> <span class="ml-auto">₹{productdata.offerprice} Make on this Upi'Id:8419810122@axl</span></h4>
    </div>
              </div>
            </div>
            
            <div class="absolute left-0 bottom-0 bg-[#444] w-full p-4">
              <h4 class="flex flex-wrap gap-4 text-base text-white">Total <span class="ml-auto">₹{productdata.offerprice}</span></h4>
            </div>
          </div>
        </div>
        <div class="xl:col-span-2 h-max rounded-md p-8 sticky top-0">
          <h2 class="text-2xl font-bold text-[#333]">Complete your order</h2>
          <form class="mt-10">
            <div>
              <h3 class="text-lg font-bold text-[#333] mb-6">Personal Details</h3>
              <div class="grid sm:grid-cols-2 gap-6">
                <div class="relative flex items-center">
                  <input type="text" placeholder="First Name"  value={userData ? userData.firstName : ''}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 24 24">
                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"></path>
                  </svg>
                </div>
                <div class="relative flex items-center">
                  <input type="text" placeholder="Middle Name"  value={userData ? userData.middleName : ''}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 24 24">
                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"></path>
                  </svg>
                </div>
                <div class="relative flex items-center">
                  <input type="text" placeholder="Last Name"  value={userData ? userData.lastName : ''}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 24 24">
                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"></path>
                  </svg>
                </div>
                <div class="relative flex items-center">
                  <input type="email" placeholder="Email"  value={userData ? userData.email : ''}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb" class="w-[18px] h-[18px] absolute right-4"
                    viewBox="0 0 682.667 682.667">
                    <defs>
                      <clipPath id="a" clipPathUnits="userSpaceOnUse">
                        <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#a)" transform="matrix(1.33 0 0 -1.33 0 682.667)">
                      <path fill="none" stroke-miterlimit="10" stroke-width="40"
                        d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                        data-original="#000000"></path>
                      <path
                        d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                        data-original="#000000"></path>
                    </g>
                  </svg>
                </div>
                <div class="relative flex items-center">
                  <input type="number" placeholder="Phone No."  value={userData ? userData.phoneNumber : ''}
                    class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                  <svg fill="#bbb" class="w-[18px] h-[18px] absolute right-4" viewBox="0 0 64 64">
                    <path
                      d="m52.148 42.678-6.479-4.527a5 5 0 0 0-6.963 1.238l-1.504 2.156c-2.52-1.69-5.333-4.05-8.014-6.732-2.68-2.68-5.04-5.493-6.73-8.013l2.154-1.504a4.96 4.96 0 0 0 2.064-3.225 4.98 4.98 0 0 0-.826-3.739l-4.525-6.478C20.378 10.5 18.85 9.69 17.24 9.69a4.69 4.69 0 0 0-1.628.291 8.97 8.97 0 0 0-1.685.828l-.895.63a6.782 6.782 0 0 0-.63.563c-1.092 1.09-1.866 2.472-2.303 4.104-1.865 6.99 2.754 17.561 11.495 26.301 7.34 7.34 16.157 11.9 23.011 11.9 1.175 0 2.281-.136 3.29-.406 1.633-.436 3.014-1.21 4.105-2.302.199-.199.388-.407.591-.67l.63-.899a9.007 9.007 0 0 0 .798-1.64c.763-2.06-.007-4.41-1.871-5.713z"
                      data-original="#000000"></path>
                  </svg>
                </div>
                <input type="text" placeholder="Enter Transaction Id" required   value={shippingInfo.transactioid}
  onChange={(e) =>
    setShippingInfo({ ...shippingInfo, transactioid: e.target.value })
  }
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
              </div>
            </div>
            <div class="mt-6">
              <h3 class="text-lg font-bold text-[#333] mb-6">Shipping Address</h3>
              <div class="grid sm:grid-cols-2 gap-6">
                <input type="text" placeholder="Address Line"   value={shippingInfo.address}
  onChange={(e) =>
    setShippingInfo({ ...shippingInfo, address: e.target.value })
  }
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                <input type="text" placeholder="City"  value={shippingInfo.city}
  onChange={(e) =>
    setShippingInfo({ ...shippingInfo, city: e.target.value })
  }
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                <input type="text" placeholder="State"  value={shippingInfo.state}
  onChange={(e) =>
    setShippingInfo({ ...shippingInfo, state: e.target.value })
  }
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
                <input type="text" placeholder="Zip Code"  value={shippingInfo.zipCode}
  onChange={(e) =>
    setShippingInfo({ ...shippingInfo, zipCode: e.target.value })
  }
                  class="px-4 py-3.5 bg-white text-[#333] w-full text-sm border-b-2 focus:border-[#333] outline-none" />
              </div>
              <div class="flex gap-6 max-sm:flex-col mt-10">
                <button onClick={() => setShowModal(false)} class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-transparent hover:bg-gray-100 border-2 text-[#333]">Cancel</button>
                <button
                type="button"
                onClick={handleSubmit}
                class="rounded-md px-6 py-3 w-full text-sm font-semibold bg-[#333] text-white hover:bg-[#222]"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Complete Purchase'}
              </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
        </div>
      )}
        </div>

        
        )}
        <ToastContainer/>
    </div>
  )
}

export default ProductDetails