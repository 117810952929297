import React, { useState, useEffect } from "react";
import { firebase } from "../Firebase/config";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      const db = firebase.firestore();
      const GallerysRef = db.collection("Gallery");

      GallerysRef.get()
        .then((querySnapshot) => {
          const gallery = [];
          querySnapshot.forEach((doc) => {
            gallery.push({ ...doc.data(), id: doc.id });
          });

          setGallery(gallery);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          setIsLoading(false);
        });
    });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      <section className="py-24">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="font-mono text-4xl font-bold text-gray-900  text-center mb-16">Our Gallery</h2>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-[#98d900] block mx-auto" viewBox="0 0 24 24">
                <path
                  d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
                  data-original="#000000"
                />
              </svg>
            </div>
          ) : (
            <div className="grid gap-y-8 gap-x-8 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              {gallery.map((Gallery, idx) => (
                <div key={idx} className="group border border-gray-300 rounded-2xl">
                  <div className="flex items-center">
                    <img src={Gallery.frontImage} alt="blogs tailwind section" className="rounded-t-2xl w-full" />
                  </div>
                  <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                    <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">{Gallery.title}</h4>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Gallery;
