const menus = [
    // {
    //     id: 1,
    //     name: 'Home',
    //     links: '/',
       
    // },
    {
        id: 2,
        name: 'SHOP BY CATEGORY',
        namesub: [
            { id: 1, sub: 'Gernal Wellness' },
            { id: 2, sub: 'Digestive Wellness' },
            { id: 3, sub: 'Immunity Wellness' },
            { id: 4, sub: 'Diabetic Wellness' },
            { id: 5, sub: 'Caradiac Wellness' },
            { id: 6, sub: 'Blood Purifier' },
            { id: 7, sub: 'Pain Reliever' },
            { id: 8, sub: "Men's Wellness" },
            { id: 9, sub: "Women's Wellness" },
            { id: 10, sub: 'Skill Wellness' },
            { id: 11, sub: 'Hair Wellness' },
            { id: 12, sub: 'Juices' },
            { id: 13, sub: 'General Wellness / Herbal Powders' },
            { id: 14, sub: 'Tablets' },
            { id: 15, sub: 'Oils' },
            { id: 16, sub: 'Cosmetic' },
        ]
    },
    // {
    //     id: 3,
    //     name: 'Markets ',
    //     links: '/markets'
    // },
    // {
    //     id: 4,
    //     name: 'SHOP BY PRODUCT',
    //     namesub: [
    //         { id: 1, sub: 'Juices' },
    //         { id: 2, sub: 'Herbal Powders/Churna' },
    //         { id: 3, sub: 'Tablets' },
    //         { id: 4, sub: 'Oils' },
    //         { id: 5, sub: 'Cosmetic' },
    //     ],
    // },
    // {
    //     id: 5,
    //     name: 'Blog',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Blog Default',
    //             links: '/blog-default'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Blog Grid v1',
    //             links: '/blog-grid-v1'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Blog Grid v2',
    //             links: '/blog-grid-v2'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Blog List',
    //             links: '/blog-list'
    //         },
    //         {
    //             id: 5,
    //             sub: 'Blog Details',
    //             links: '/blog-details'
    //         },
    //     ],
    // },
    // {
    //     id: 6,
    //     name: 'BITUSDT',
    //     links: '/wallet'
    // },

    // {
    //     id: 2,
    //     name: 'Profile',
    //     links: '/user-profile',
        
    // },
    // {
    //     id: 3,
    //     name: 'Login',
    //     links: '/signinsinup',
        
    // },
    
]

export default menus;