import React, { useState, useEffect } from "react";
import { firebase } from "../Firebase/config";

const db = firebase.firestore();

const MenuPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category");
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        let ProductRef = db.collection("Product");

        // If category exists in the URL, apply the filter
        if (category) {
          ProductRef = ProductRef.where("productcategory", "==", category);
        }

        const snapshot = await ProductRef.get();
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          averageRating: 0, // Initialize average rating for each product
        }));

        setProductData(data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchProductData();
  }, [category]);
    console.log("productdata",productData)
    // Calculate average rating for each product
    useEffect(() => {
      productData.forEach(async (item, index) => {
        const reviewsRef = db
          .collection("Product")
          .doc(item.id)
          .collection("reviews")
          .where("approved", "==", "Active");
  
        const snapshot = await reviewsRef.get();
        const reviewsData = snapshot.docs.map((doc) => doc.data());
  
        if (reviewsData.length > 0) {
          const totalRating = reviewsData.reduce(
            (acc, curr) => acc + curr.rating,
            0
          );
          const avgRating = totalRating / reviewsData.length;
          const updatedProductData = [...productData];
          updatedProductData[index].averageRating = avgRating;
          setProductData(updatedProductData);
        }
      });
    }, [productData]);
    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-10 animate-spin fill-[#98d900] block mx-auto" viewBox="0 0 24 24">
          <path
            d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
            data-original="#000000"
          />
        </svg>
      </div>; // Display loading indicator
      }
    return (
        <div className="pb-2">
      <div >
        <div className="2xl:container 2xl:mx-auto flex flex-col justify-center items-center sm:pb-12 lg:pb-0 space-y-4 px-4 md:px-6 2xl:px-0">
          <div>
            <p className="text-2xl font-sans uppercase lg:text-4xl font-semibold leading-9 text-center text-[#98d900]">
              Our Products
            </p>
          </div>

        </div>
      </div>
      {productData.length === 0 ? (
        <div className="text-center font-mono text-2xl text-gray-500 mt-12">
          No Product Available, Coming Soon
        </div>
      ) : (
      <div className="px-4 flex justify-center py-4">
        <div className="grid grid-cols-1 gap-4 sm:gap-6 lg:gap-8 sm:grid-cols-2 lg:grid-cols-4 w-full max-w-7xl">
          {productData.map((item, index) => (
            <div key={index} className="w-full max-w-sm mx-auto lg:max-w-none">
              <div className="p-4 bg-white rounded shadow dark:bg-gray-700 transition duration-300 transform hover:-translate-y-1 hover:shadow-xl">
                <a
                  href={`/Product-Details?id=${item.id}`}
                  className="block overflow-hidden"
                >
                  <img
                    className="w-full h-64  object-cover rounded-lg"
                    src={item.images[0]} // Use the first image URL here
                    alt={item.title}
                  />
                </a>
                {/* <p className="text-sm font-semibold text-gray-600 mt-1">
                  {renderStars(item.averageRating)} {item.averageRating.toFixed(1)} / 5 <div className="flex">

                  </div>
                </p> */}
                <a href={`/Product-Details?id=${item.id}`} className="overflow-hidden block h-12">
                <h3 className="text-lg font-sans uppercase font-bold text-[#98d900] dark:text-white mb-1 truncate overflow-hidden">
                {item.title}
                </h3>
              </a>
                <p className="mb-1 text-xl font-bold text-[#98d900] dark:text-[#98d900] ">
                  <span>₹{item.offerprice}</span>
                  <span className="text-sm font-semibold text-gray-400 ml-2 line-through ">₹{item.price}</span>
                </p>

                <div className="flex justify-center bg-[#98d900] space-x-4 p-2 rounded-lg">
                  {/* {item.Stock === 'In stock' ? (
                    <button
                      
                      className="flex  font-sans uppercase items-center justify-center font-bold text-sm text-center text-white dark:text-white group"
                    >
                      <h1 className="text-xl ">🛒 </h1> Add to Cart
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="ml-2 transition-all group-hover:translate-x-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button disabled className="flex  font-sans uppercase items-center justify-center font-bold text-sm text-center text-white cursor-not-allowed dark:text-white group">
                      <h1 className="text-xl text-white"> </h1> Item Out of Stock
                    </button>
                  )} */}
                  <a
                    href={`/Product-Details?id=${item.id}`}
                    className="flex  font-sans uppercase items-center justify-center font-bold text-center text-white dark:text-white text-sm group"
                  >
                    <h1 className="text-xl">👁️‍🗨️</h1>View Product
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="ml-2 transition-all group-hover:translate-x-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </a>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
      )}

    </div>
    );
};

export default MenuPage;
