
import React, { useState, useEffect } from "react";
import { firebase } from "../Firebase/config";
import "firebase/firestore";
import "firebase/storage";
import { useLocation } from 'react-router-dom';

const OrderDetails = () => {
    const location = useLocation();
    const [orderdata, setOrderData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const orderId = queryParams.get('orderId'); 
    
        const db = firebase.firestore();
        const productRef = db.collection("orders").doc(orderId);
    
        productRef.get().then((doc) => {
          if (doc.exists) {
            setOrderData(doc.data());
          } else {
            console.log("Document not found!");
          }
          setIsLoading(false);
        });
      }, [location]);
      console.log("orderdata",orderdata)
  return (
    <div>
                                                        <section class="py-24 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
            <div class="w-full flex-col justify-start items-center lg:gap-12 gap-8 inline-flex">
                <div class="flex-col justify-start items-center gap-3 flex">
                    <h2 class="text-center text-gray-900 text-3xl font-bold font-manrope leading-normal">{orderdata?.firstName}, Thank You for Your Order  !</h2>
                    <p class="max-w-xl text-center text-gray-500 text-lg font-normal leading-8">Your order is in good hands! We'll notify you once it's en route. For now, here's a snapshot of your purchase</p>
                </div>
                <div class="w-full flex-col justify-start items-center lg:gap-10 gap-8 flex">
                    <div class="w-full flex-col justify-start items-start gap-6 flex">
                        <div class="w-full flex-col justify-start items-start gap-5 flex">
                            <div class="w-full md:px-6 px-2 py-4 border-y border-gray-200 justify-between items-center inline-flex">
                                <h3 class="text-gray-900 text-xl font-medium leading-8">Item</h3>
                                <h3 class="text-right text-gray-900 text-xl font-medium leading-8">Total</h3>
                            </div>
                            <div class="w-full md:px-6 px-2 pb-5 justify-between items-center gap-8 inline-flex border-b border-gray-300">
                                <div class="justify-start items-center gap-6 flex md:pb-5">
                                    <img class="w-32 h-32 object-contain" src={orderdata?.productimage} alt="Simple Black T-shirt image" />
                                    <div class="flex-col justify-start items-start gap-1.5 inline-flex">
                                        <h5 class="text-gray-900 text-lg font-semibold leading-relaxed">{orderdata?.productname}</h5>
                                        <h6 class="text-gray-500 text-base font-normal leading-relaxed">QTY: 1</h6>
                                    </div>
                                </div>
                                <h4 class="text-right text-gray-900 text-lg font-medium leading-relaxed">₹{orderdata?.productprice}</h4>
                            </div>
                        </div>
                        <div class="w-full md:pt-6 justify-start items-center gap-5 flex md:flex-row flex-col">
                            <div class="w-full md:px-6 px-2 flex-col justify-start items-start gap-5 inline-flex md:border-r md:border-b-0 border-b md:pb-0 pb-5 border-gray-200">
                                {/* <div class="w-full justify-between items-center inline-flex gap-4">
                                    <h5 class="text-gray-500 text-lg font-normal leading-relaxed">Estimated Delivery:</h5>
                                    <h5 class="text-right text-gray-500 text-lg font-normal leading-relaxed">Friday, 3rd Jun 2023</h5>
                                </div> */}
                                <div class="w-full justify-between items-center inline-flex gap-4 border-y border-gray-200 py-5">
                                    <h5 class="text-gray-500 text-lg font-normal leading-relaxed">Delivery Address:</h5>
                                    <h5 class="text-right text-gray-500 text-lg font-normal leading-relaxed">{orderdata?.address},{orderdata?.city},{orderdata?.state} </h5>
                                </div>
                                <div class="w-full justify-between items-center inline-flex gap-4">
                                    <h5 class="text-gray-500 text-lg font-normal leading-relaxed">Payment Status</h5>
                                    <h5 class="text-right text-gray-500 text-lg font-normal leading-relaxed">{orderdata?.orderConfirmation}</h5>
                                </div>
                            </div>
                            <div class="w-full md:px-6 px-2 flex-col justify-start items-start gap-5 inline-flex">
                                <div class="w-full pb-6 border-b border-gray-200 flex-col justify-start items-start gap-6 flex">
                                    <div class="w-full justify-between items-start gap-6 inline-flex">
                                        <h5 class="text-gray-500 text-lg font-normal leading-relaxed">Subtotal</h5>
                                        <h5 class="text-right text-gray-900 text-lg font-semibold leading-relaxed">₹{orderdata?.productprice}</h5>
                                    </div>
                                    {/* <div class="w-full justify-between items-start gap-6 inline-flex">
                                        <h5 class="text-gray-500 text-lg font-normal leading-relaxed">Shipping</h5>
                                        <h5 class="text-right text-gray-900 text-lg font-semibold leading-relaxed">$20.00</h5>
                                    </div> */}
                                </div>
                                <div class="w-full justify-between items-start gap-6 inline-flex">
                                    <h4 class="text-indigo-600 text-xl font-semibold leading-8">Total</h4>
                                    <h4 class="text-right text-indigo-600 text-xl font-semibold leading-8">₹{orderdata?.productprice}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full justify-center items-center gap-8 flex sm:flex-row flex-col">
                        <a href="/" class="md:w-fit w-full px-5 py-2.5 bg-indigo-50 hover:bg-indigo-100 transition-all duration-700 ease-in-out rounded-xl justify-center items-center flex">
                            <span class="px-2 py-px text-indigo-600 text-base font-semibold leading-relaxed">Back to Shopping</span>
                        </a>                
                        {/* <button class="md:w-fit w-full px-5 py-2.5 bg-indigo-600 hover:bg-indigo-800 transition-all duration-700 ease-in-out rounded-xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex">
                            <span class="px-2 py-px text-white text-base font-semibold leading-relaxed">Track My Order</span>
                        </button>     */}
                    </div>
                </div>
            </div>
        </div>
    </section>
                                            
    </div>
  )
}

export default OrderDetails