import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { firebase } from '../Firebase/config';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // 0 for Login tab, 1 for Signup tab

  const navigate = useNavigate();


  const [referralId, setReferralId] = useState(false);
const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralId = searchParams.get('refferal');
    setReferralId(referralId); // Print the referral ID to console (you can do anything you want with it)
  }, [location.search]);

const handleLogin = async () => {
  try {
    setLoading(true);

    // Use email and password states for form values
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    const user = userCredential.user;

    setLoading(false);
    toast.success('Login successful!');
    console.log('User logged in successfully:', user);

    // Redirect to the home page after successful login
    navigate('/');
  } catch (error) {
    setLoading(false);

    let errorMessage;

    // Handle specific Firebase Authentication error codes
    switch (error.code) {
      case 'auth/invalid-email':
        errorMessage = 'Invalid email address.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Incorrect password.';
        break;
      case 'auth/user-not-found':
        errorMessage = 'No user found with this email.';
        break;
      default:
        errorMessage = `Invalid email address and password`;
        break;
    }

    toast.error(errorMessage);
    console.error('Error during login:', error.message);
  }
};



  const handleRegister = async () => {
    const firstName = document.getElementById('firstName').value;
    const middleName = document.getElementById('middleName').value;
    const lastName = document.getElementById('lastName').value;
    const phoneNumber = document.getElementById('phonenumber').value;
    const upino = document.getElementById('upino').value;
    const upiid = document.getElementById('upiid').value;
    const fulladdress = document.getElementById('fulladdress').value;
    const city = document.getElementById('city').value;
    const pincode = document.getElementById('pincode').value;

    if (!firstName || !middleName || !lastName || !phoneNumber || !email || !upino || !upiid || !fulladdress || !city || !pincode) {
      toast.error('Please fill in all required fields.');
      return;
    }

    try {
      setLoading(true);

      // Create user with email and password
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;

      // Store additional user details in Firestore
      await firebase.firestore().collection('users').doc(user.uid).set({
        firstName: document.getElementById('firstName').value,
        middleName: document.getElementById('middleName').value,
        lastName: document.getElementById('lastName').value,
        phoneNumber: document.getElementById('phonenumber').value,
        email: email,
        upino: document.getElementById('upino').value,
        upiid: document.getElementById('upiid').value,
        fulladdress: document.getElementById('fulladdress').value,
        city: document.getElementById('city').value,
        pincode: document.getElementById('pincode').value,
        referralId:referralId,
        registrationDate: firebase.firestore.FieldValue.serverTimestamp(),
        // Add other user details as needed
      });

      setLoading(false);
      toast.success('Registration successful!');
      console.log('User registered successfully:', user);

      // Switch to the Login tab after successful registration
      setSelectedIndex(0);
    } catch (error) {
      setLoading(false);
      toast.error(`Error during registration: ${error.message}`);
      console.error('Error during registration:', error.message);
    }
  };
    return (
        <div>
             
             {/* <PageTitle heading='Login' title='Login' /> */}


            <section className="register login">
            <div className="container">
                <div className="row">
               
                <div className="col-md-12">
                <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
                    
                    <TabList>
      
                        <Tab><h6 className="fs-16">Login</h6></Tab>
                        <Tab><h6 className="fs-16">Signup</h6></Tab>

                    </TabList>



                    <TabPanel>
                    <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Login To Medherbs</h3>
                    <p className="desc fs-20">
                        Welcome back! Log In now to start trading
                    </p>
                    {/* <div className="lock">
                        <div className="icon">
                        <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M8.00004 11.7578C7.67672 11.7578 7.41406 12.0205 7.41406 12.3438C7.41406 12.6671 7.67672 12.9298 8.00004 12.9298C8.32336 12.9298 8.58602 12.6671 8.58602 12.3438C8.58602 12.0205 8.32336 11.7578 8.00004 11.7578Z"
                            fill="white"
                            />
                            <path
                            d="M11.5162 8.24219H4.2187C2.10011 8.24219 0.382568 9.95965 0.382568 12.0783C0.382568 15.6973 2.78413 19.0605 6.32241 19.8205C11.2508 20.8791 15.618 17.0922 15.618 12.344C15.618 10.0787 13.7816 8.24219 11.5162 8.24219ZM8.58628 13.9941V17.071C8.58628 17.3949 8.32417 17.657 8.0003 17.657C7.6764 17.657 7.41433 17.3949 7.41433 17.071V13.9941C6.73374 13.7514 6.24237 13.107 6.24237 12.3441C6.24237 11.3747 7.03093 10.5861 8.0003 10.5861C8.96968 10.5861 9.75823 11.3747 9.75823 12.3441C9.75823 13.107 9.26686 13.7513 8.58628 13.9941Z"
                            fill="white"
                            />
                            <path
                            d="M8.00039 0C5.08223 0 2.72656 2.35562 2.72656 5.27383V7.3234C3.20102 7.17391 3.69582 7.07086 4.21898 7.07086H5.07051V5.27383C5.07051 3.65652 6.38309 2.34395 8.00039 2.34395C9.6177 2.34395 10.9303 3.65652 10.9303 5.27383V7.07082H11.5163C12.1356 7.07082 12.7216 7.19777 13.2742 7.3948V5.27383C13.2742 2.35844 10.9128 0 8.00039 0Z"
                            fill="white"
                            />
                        </svg>
                        </div>
                        <p><span>https://</span>accounts.Medherbs.com/login</p>
                    </div> */}
                    </div>
                </div>
                        <div className="content-inner">
                        <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                                <div className="form-group">
                                <label for="exampleInputEmail1">Email/ID</label>
                                <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Please fill in the email form."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
                                </div>
                                <div className="form-group s1">
                                <label>Password </label>
                                <input
                type="password"
                className="form-control"
                placeholder="Please enter a password."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

                                </div>

                                <div className="form-group form-check">
                                <div>
                                    <input type="checkbox" className="form-check-input" />
                                    <label className="form-check-label">Remember Me</label>
                                </div>
                                <p>Forgot Password?</p>
                                </div>

                                <button
                type="button"
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  fontSize: '16px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleLogin}
              >
                {loading ? 'Loading...' : 'Login'}
              </button>
                                {/* <div className="bottom">
                                <p>Not a member?</p>
                                <Link to="/register">Register</Link>
                                </div> */}
                            </form>
                        </div>
                    </TabPanel>

                    <TabPanel>
                    <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Signup To Medherbs</h3>
                    <p className="desc fs-20">
                        Welcome back! Signup now to start trading
                    </p>
                    {/* <div className="lock">
                        <div className="icon">
                        <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M8.00004 11.7578C7.67672 11.7578 7.41406 12.0205 7.41406 12.3438C7.41406 12.6671 7.67672 12.9298 8.00004 12.9298C8.32336 12.9298 8.58602 12.6671 8.58602 12.3438C8.58602 12.0205 8.32336 11.7578 8.00004 11.7578Z"
                            fill="white"
                            />
                            <path
                            d="M11.5162 8.24219H4.2187C2.10011 8.24219 0.382568 9.95965 0.382568 12.0783C0.382568 15.6973 2.78413 19.0605 6.32241 19.8205C11.2508 20.8791 15.618 17.0922 15.618 12.344C15.618 10.0787 13.7816 8.24219 11.5162 8.24219ZM8.58628 13.9941V17.071C8.58628 17.3949 8.32417 17.657 8.0003 17.657C7.6764 17.657 7.41433 17.3949 7.41433 17.071V13.9941C6.73374 13.7514 6.24237 13.107 6.24237 12.3441C6.24237 11.3747 7.03093 10.5861 8.0003 10.5861C8.96968 10.5861 9.75823 11.3747 9.75823 12.3441C9.75823 13.107 9.26686 13.7513 8.58628 13.9941Z"
                            fill="white"
                            />
                            <path
                            d="M8.00039 0C5.08223 0 2.72656 2.35562 2.72656 5.27383V7.3234C3.20102 7.17391 3.69582 7.07086 4.21898 7.07086H5.07051V5.27383C5.07051 3.65652 6.38309 2.34395 8.00039 2.34395C9.6177 2.34395 10.9303 3.65652 10.9303 5.27383V7.07082H11.5163C12.1356 7.07082 12.7216 7.19777 13.2742 7.3948V5.27383C13.2742 2.35844 10.9128 0 8.00039 0Z"
                            fill="white"
                            />
                        </svg>
                        </div>
                        <p><span>https://</span>accounts.Medherbs.com/login</p>
                    </div> */}
                    </div>
                </div>
                <div className="content-inner">
                            <form>
                            <div className='flex flex-col md:flex-row'>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="firstName">First Name</label>
        <input
            type="text"
            className="form-control"
            id="firstName"
            placeholder="Enter Your First Name."
        />
    </div>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="middleName">Middle Name</label>
        <input
            type="text"
            className="form-control"
            id="middleName"
            placeholder="Enter Your Middle Name."
        />
    </div>
    <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input
            type="text"
            className="form-control"
            id="lastName"
            placeholder="Enter Your Last Name."
        />
    </div>
</div>
<div className="form-group">
                                <label for="phonenumber">Mobile Phone</label>
                                <div>
                                    {/* <select
                                    className="form-control"
                                    id="phonenumber"
                                    >
                                    <option>+91</option>
                                    <option>+84</option>
                                    <option>+82</option>
                                    <option>+32</option>
                                    </select> */}
                                    <input
                                    type="text"
                                    id="phonenumber"
                                    className="form-control"
                                    placeholder="Your Phone number"
                                    />
                                </div>
                                </div>

                                <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email/ID</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Please fill in the email form."
                                    value={email}  // Add this line to bind the value to the email state
                                    onChange={(e) => setEmail(e.target.value)}  // Add this line to update the email state
                                />
                            </div>
                                <div className="form-group">
        <label htmlFor="upiid">UPI ID</label>
        <input
            type="text"
            className="form-control"
            id="upiid"
            placeholder="Enter Your UPI ID."
        />
    </div>
    <div className="form-group">
        <label htmlFor="upino">UPI No.</label>
        <input
            type="text"
            className="form-control"
            id="upino"
            placeholder="Enter Your UPI No."
        />
    </div>
    
    <div className="form-group">
        <label htmlFor="fulladdress">Full Address</label>
        <input
            type="text"
            className="form-control"
            id="fulladdress"
            placeholder="Enter Your Full Address."
        />
    </div>
    <div className='flex flex-col md:flex-row'>
    <div className="form-group" style={{ marginRight: '10px' }}>
        <label htmlFor="city">City</label>
        <input
            type="text"
            className="form-control"
            id="city"
            placeholder="Enter Your City."
        />
    </div>
  
    <div className="form-group">
        <label htmlFor="pincode">Pin Code</label>
        <input
            type="text"
            className="form-control"
            id="pincode"
            placeholder="Enter Your Pin Code."
        />
    </div>
</div>
<div className="form-group">
        <label htmlFor="refferedid">Reffered By</label>
        <input
            type="text"
            className="form-control"
            id="refferedid"
            value={referralId}
            placeholder="Enter Reffered Id."
        />
    </div>
                                <div className="form-group s1">
                                <label>Password </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Please enter a password."
                                    value={password}  // Add this line to bind the value to the password state
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                </div>

                                <div className="form-group form-check">
                                {/* <div>
                                    <input type="checkbox" className="form-check-input" />
                                    <label className="form-check-label">Remember Me</label>
                                </div> */}
                                {/* <p>Forgot Password?</p> */}
                                </div>

                                <button
                type="button"
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  fontSize: '16px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleRegister}
              >
                {loading ? 'Loading...' : 'Register'}
              </button>

                            </form>
                        </div>
                    </TabPanel>

                    

                </Tabs> 

                    {/* <div className="qr-code center">
                    <img src={img} alt="" />
                    <h6 className="fs-20">Login with QR code</h6>
                    <p className="fs-14">
                        Scan this code with the <span>Medherbs mobile app</span> <br />
                        to log in instantly.
                    </p>
                    </div> */}
                </div>
                </div>
            </div>
            </section>
{/* 
            <Sale01 /> */}
            <ToastContainer/>
        </div>
    );
}

export default Login;